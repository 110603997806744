import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { valUsuario } from '../validaciones/validaciones';
import { Usuario } from 'src/app/models/usuario.model';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent {
  constructor(private formService:UsuarioService, private router: Router,private fb: FormBuilder, private toastr: ToastrService) { }
  @Input() formObj:string ="";
  recuperar() {
    console.info("Form object", this.formObj);
    let s =this.formService.recuperar(this.formObj);
    s.then(resultado =>{
      console.warn("Resultado:", resultado);
      if (resultado >0){
        this.router.navigate(['/login']);
        this.toastr.success('Contraseña enviada correctamente, revise su correo', 'Contraseña enviada')

      }
      else{
        this.toastr.error('el usuario no existe','Error')
      }
    });
  }
  Formulario: FormGroup = this.fb.group({
    usuario: ['', [Validators.required, Validators.pattern(valUsuario)]],
  })
  campoNoValido(campo:string){
    return this.Formulario.get(campo)?.invalid
    && this.Formulario.get(campo)?.touched;
  }
  // submitformulario(){
  //   console.warn("registrando...",this.Formulario.valid)
  //   if(this.Formulario.valid){
  //     console.log("enviando.....")
  //     this.recuperar()
  //   }
  //   this.toastr.error('El usuario no existe','Error')
  //   // this.Formulario.markAllAsTouched();c
  // }
  
  
}
