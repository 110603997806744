export const valNombre: string = '([a-zA-Z]+)';
export const valApellidos: string = '([a-zA-Z]+) ([a-zA-Z]+)';
export const valTelefono: string = '[0-9]{10}';
export const valUsuario: string = '.{5,}';
export const valPassword: string = '.{8,}';
export const valcorreo: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";


// export const nopuedeserstrider = (control: FormControl) => {
//     const valor: string = control.value?.trim().toLowerCCase();
//     if (valor === 'strider') {
//         return {
//             noStrider: true
//         }
//     }
//     return null;
// }