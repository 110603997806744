import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input() IdUsuario :number =0;
  constructor(private router: Router) { 
    let idUsuario = localStorage.getItem("IdUsuario");
    console.warn("usuario actual", idUsuario)
    if (idUsuario != null)
      this.IdUsuario = parseInt(idUsuario);
  }

  cerrar(): void {
    localStorage.clear();
    this.router.navigate(["/login"]);
  }

  status: boolean = true;
  clickEvent() {
    /*var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }*/
    this.status = !this.status;
  }
}
