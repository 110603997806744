import { Component, Input, OnInit } from '@angular/core';
import { Producto } from 'src/app/models/producto.model';
import { ProductoService } from 'src/app/services/producto.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-card-products',
  templateUrl: './card-products.component.html',
  styleUrls: ['./card-products.component.css']
})
export class CardProductsComponent implements OnInit {
  @Input() IdUsuario :number =0;
  public page!:number;
  public smallSize:boolean=false;
  constructor(private productService:ProductoService, private toastr: ToastrService) { }

  listProductos:Array<Producto> = new Array();

  ngOnInit(): void {
    console.log("Width", window.innerWidth);
    this.smallSize = window.innerWidth<1000;
    let idUsr = localStorage.getItem("IdUsuario");
    console.warn("Lodaing data for user:",idUsr);
    if (idUsr != null)
      this.IdUsuario = parseInt(idUsr);

    this.obtenerProductos();
  }

  obtenerProductos(){
    this.productService.lista().then((lista)=>{
      if (lista != null)
        this.listProductos = lista;
      //console.warn("data downloaded:", this.listProductos);
    });
  }

  calificar(prod:Producto, calificacion:number){
    prod.Cantidad = calificacion;
    this.productService.calificar(prod).then((res) =>{
      if(res >0){
        this.toastr.success('¡Producto calificado!', 'Carrito');
      }else{
        this.toastr.error('¡Algo salió mal!', 'Carrito');
      }
    });
  }

  sumarVistas(prod:Producto){
    prod.Cantidad  = 1;
    this.productService.calificar(prod).then((res) =>{
      if(res >0){
        console.log("Click registrado")//this.toastr.success('¡Producto calificado!', 'Carrito');
      }else{
        console.log('¡Algo salió mal!');
      }
    });
  }

  carrito(id:number){
    let info = localStorage.getItem("carrito");
    //console.info("Carrito", info);
    let carrito = new Map<string, number>();
    if (info != null){
      let json = JSON.parse(info);
      carrito = new Map(Object.entries<number>(json));
    }

    let contador = carrito.get(""+id);
    if (contador !=undefined)
      contador+=1;
    else
      contador =1;
    carrito.set(""+id, contador);

    let jsonN =  Object.fromEntries(carrito);
    localStorage.setItem("carrito", JSON.stringify(jsonN));
    this.toastr.success('Producto agregado al carrito', 'Producto');
  }
}
