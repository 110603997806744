import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, mergeMap, Observable, of } from 'rxjs';
import { Producto } from '../models/producto.model';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {
  private url = "https://api2.buscomida.mx/cliente/productos";
  constructor(private http: HttpClient) { }

  lista(ids?:string):Promise<Array<Producto>|null>{
    //if (ids != undefined && ids.length ==0)
    let url = this.url +(ids !=undefined && ids.length >0 ?"?Ids="+ids :"");
    let req = this.http.get<{Datos:Array<Producto>}>(url, { observe: 'response' });

    return new Promise<Array<Producto>|null>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve([]);
          return of();
        })
      ).subscribe(response =>{
          resolve(response.ok && response.body!=null ?response.body.Datos :null);
      });
    });
  }

  compra(lista:Array<Producto>):Promise<number>{
    let c =0;
    let req:Observable<HttpResponse<{Resultado: number;}>>
    lista.forEach(prod => {
      let frmObj = {Id:prod.Id, Cantidad:prod.Cantidad, Tipo:2}
      if (req == null)
        req = this.http.post<{Resultado:number}>(this.url, frmObj, { observe: 'response' });
      else
        req = req.pipe(mergeMap(res => this.http.post<{Resultado: number}>(this.url, frmObj, { observe: 'response' })))
    });
    
    return new Promise<number>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve(0);
          return of();
        })
      ).subscribe(response =>{
        resolve(response.ok && response.body!=null ?response.body.Resultado :0);
      });
    });
  }

  calificar(frmObj:Producto):Promise<number>{
    let req = this.http.post<{Resultado:number}>(this.url, {Id:frmObj.Id, Cantidad:frmObj.Cantidad, Tipo:3}, { observe: 'response' });
    return new Promise<number>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve(0);
          return of();
        })
      ).subscribe(response =>{
        resolve(response.ok && response.body!=null ?response.body.Resultado :0);
      });
    });
  }

  sumarVistas(frmObj:Producto):Promise<number>{
    let req = this.http.post<{Resultado:number}>(this.url, {Id:frmObj.Id, Cantidad:frmObj.Cantidad, Tipo:1}, { observe: 'response' });
    return new Promise<number>((resolve, reject)=>{
      req.pipe(catchError(err => {
          console.error("Ocurrio un error", err);
          resolve(0);
          return of();
        })
      ).subscribe(response =>{
        resolve(response.ok && response.body!=null ?response.body.Resultado :0);
      });
    });
  }
}
