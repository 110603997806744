import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import {Router} from "@angular/router";

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent extends BaseComponent {

  constructor( router: Router) { 
    super(router)
  }

  /*override ngOnInit(): void {
    super.ngOnInit();
  }*/

}
