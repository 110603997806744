import { Component, OnInit, Input } from '@angular/core';
import { Sesion } from 'src/app/models/sesion.model';
import { SesionService } from 'src/app/services/sesion.service';
import {Router} from "@angular/router"
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { valPassword, valUsuario } from '../validaciones/validaciones';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  @Input() formObj: Sesion = new Sesion();
  constructor(private formService:SesionService,private fb: FormBuilder, private router: Router, private toastr: ToastrService) { }

  crear() {
    console.info("Form object", this.formObj);
    let s =this.formService.crear(this.formObj);
    s.then(resultado =>{
      console.warn("Resultado:", resultado);
      if (resultado >0){
        localStorage.setItem("IdUsuario", ""+resultado);//this.cookieService.set('IdUsuario', ""+resultado);
        this.router.navigate(['/inicio']);
        this.toastr.success('Has iniciado sesion correctamente', '¡Bienvenido!');
      }
      else{
        this.toastr.error('¡Usuario o contraseña incorrecto!', 'Datos incorrectos')
      }
    });
  }
  Formulario: FormGroup = this.fb.group({
    usuario: ['', [Validators.required, Validators.pattern(valUsuario)]],
    password: ['', [Validators.required, Validators.pattern(valPassword)]],
  })
  campoNoValido(campo:string){
    return this.Formulario.get(campo)?.invalid
    && this.Formulario.get(campo)?.touched;
  }

  // submitformulario(){
  //   console.warn("registrando...",this.Formulario.valid)
  //   if(this.Formulario.valid){
  //     console.log("enviando.....")
  //     this.crear()
  //   }
  //   this.Formulario.markAllAsTouched();
  // }
}
