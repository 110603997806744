<app-header></app-header>
<div class="container">
  <table class="content-table">
    <thead>
      <tr>
        <th>Articilo</th>
        <th>Descripcion</th>
        <th>Precio</th>
        <th>Cantidad</th>
        <th>Eliminar</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let producto of listProductos">
        <td>{{producto.Nombre}} <img src='{{ "https://api2.buscomida.mx/cliente/productos/" + producto.Id }}' alt=""></td>
        <td>{{producto.Descripcion}}</td>
        <td>{{producto.Precio}}</td>
        <td>{{producto.Cantidad}}</td>
        <td>
          <a class="link_delete" (click)="quitar(producto)"><i class="fa-solid fa-trash-can"></i></a>
        </td>
      </tr>
      <!--<tr>
        <td>Tacos <img src="assets/img/untitled-copy.jpg" alt=""></td>
        <td>Pastor</td>
        <td>50</td>
        <td>5</td>
        <td>
          <a class="link_delete" href=""><i class="fa-solid fa-trash-can"></i></a>
        </td>
      </tr>
      <tr>
        <td>Tacos <img src="assets/img/untitled-copy.jpg" alt=""></td>
        <td>Pastor</td>
        <td>50</td>
        <td>5</td>
        <td>
          <a class="link_delete" href=""><i class="fa-solid fa-trash-can"></i></a>
        </td>
      </tr>
      <tr>
        <td>Tacos <img src="assets/img/untitled-copy.jpg" alt=""></td>
        <td>Pastor</td>
        <td>50</td>
        <td>5</td>
        <td>
          <a class="link_delete" href=""><i class="fa-solid fa-trash-can"></i></a>
        </td>
      </tr>-->
    </tbody>
    <a class="btn-shopping" (click)="comprar()">Comprar <i class="fa-solid fa-bag-shopping"></i></a>
    <p>Total:${{total}}</p>
  </table>
</div>
<app-footer></app-footer>