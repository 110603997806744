import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import {PasswordRecoveryComponent} from './components/password-recovery/password-recovery.component';
import { CardProductsComponent } from './components/card-products/card-products.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { FrmProductsComponent } from './components/frm-products/frm-products.component';
import { Error404Component } from './components/errors/error404/error404.component';
import { DetProductsComponent } from './components/det-products/det-products.component';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'inicio', component:InicioComponent},
  { path: 'login', component:LoginComponent },
  { path: 'registro', component:SignUpComponent},
  { path: 'recuperarContrasena', component:PasswordRecoveryComponent},
  { path: 'catalogo', component:CardProductsComponent},
  { path: 'carrito', component:ShoppingCartComponent},
  { path: 'registroProductos', component:FrmProductsComponent},
  { path: 'error404', component:Error404Component},
  { path: 'detalleProducto', component:DetProductsComponent},

  { path: '**', redirectTo: 'error404', pathMatch: 'full' },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
/**
 * Productos
 * Usuarios
 * Ventas
 
 localhost/               *inico
 localhost/Productos/     *Productos
 */
