<app-header></app-header>
<div class="contenedor">
    <section class="contenido-header">
        <section class="textos-header">
            <h1>La mejor comida en BusComida</h1>
            <p>¿Nesecitas comida? Nosotros nos ponemos a disponibilidad tuya.</p>
            <a class="btn-detalle">Ver mas</a>
        </section>
        <img src="assets/img/storefront_illustration_2.png" alt="">
    </section>
</div>
<app-card-products></app-card-products>
<app-footer></app-footer>