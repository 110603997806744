<div class="con">
  <div class="container">
    <header>Registro de productos</header>
    <form action="#">
      <div class="form first">
        <div class="details personal">
          <div class="fields">
            <div class="input-field">
              <label>Nombre del producto</label>
              <input type="text" placeholder="Nombre del producto" required />
            </div>

            <div class="input-field">
              <label>Descripción</label>
              <textarea required> </textarea>
            </div>

            <div class="input-field">
              <label>Categoria</label>
              <input type="textarea" placeholder="Categoría" required />
            </div>

              <div class="input-field">
                <label>Imagen</label>
                <input class="btn-file" type="file" required />
              </div>

            <div class="input-field">
              <label>Precio</label>
              <input type="number" placeholder="Precio" required />
            </div>
          </div>
          <button class="nextBtn">
            <span class="btnText">Registrar</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
