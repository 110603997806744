import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Producto } from 'src/app/models/producto.model';
import { ProductoService } from 'src/app/services/producto.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {
  listProductos:Array<Producto> = new Array();
  total =0;
  carrito = new Map<string, number>();
  constructor(private productService:ProductoService, private router: Router, private toastr: ToastrService) {
    let info = localStorage.getItem("carrito");
    if (info == null)
      return;    
    
    let json = JSON.parse(info);
    this.carrito = new Map(Object.entries<number>(json));
    
  }

  ngOnInit(): void {
    if (this.carrito.size >0)
      this.obtenerProductos()
  }

  obtenerProductos(){
    this.total =0;
    let ids = Array.from(this.carrito.keys()).join(",");
    this.productService.lista(ids).then((lista)=>{
      if (lista == null)
        return;
      
        lista.forEach((prod, i, a)=>{
          let cant =this.carrito.get(""+prod.Id);
          prod.Cantidad = cant !=undefined ?cant :0;
          this.total+=prod.Precio*prod.Cantidad;
        });
      this.listProductos = lista;
    });
  }

  quitar(prod:Producto){
    let index = this.listProductos.indexOf(prod);
    this.listProductos.splice(index, 1);
    this.carrito.delete(""+prod.Id);
    this.total-=prod.Cantidad*prod.Precio;

    let jsonN =  Object.fromEntries(this.carrito);
    localStorage.setItem("carrito", JSON.stringify(jsonN));
    this.toastr.info('Producto Elminado', 'Carrito')
  }

  comprar(){
    if (this.listProductos.length ==0){
      this.toastr.error('No hay productos en el carrito', 'Carrito');
      return;
    }

    if (localStorage.getItem("IdUsuario") == null){
      this.toastr.warning('Es necesario iniciar sesión para continuar con la compra', 'Carrito');
        this.router.navigate(["/login"]);
      return;
    }

    this.productService.compra(this.listProductos).then((res)=>{
      if(res >0){
        this.carrito.clear();
        this.listProductos = new Array();
        this.total =0;
        localStorage.removeItem("carrito");
        this.toastr.success('¡Pedido realizado!', 'Carrito');
      }else{
        this.toastr.error('¡Algo salió mal!', 'Carrito');
      }
    })
  }
}
