<h1 class="title">Los mejores platillos</h1>
<div class="c">
  <div class="container">
    <div *ngFor="let producto of listProductos | paginate: { itemsPerPage: smallSize ?1 :3, currentPage: page }" class="card">
      <img src='{{ "https://api2.buscomida.mx/cliente/productos/" + producto.Id }}'>
      <h4>{{producto.Nombre}}</h4>
      <p>{{producto.Descripcion}}</p>
      <div class="star" *ngIf="IdUsuario >0">
        <input type="radio" name="rate" id="rate-5-{{producto.Id}}">
        <label for="rate-5-{{producto.Id}}" class="fa-solid fa-star" (click)="calificar(producto, 5)"></label>
        <input type="radio" name="rate" id="rate-4-{{producto.Id}}">
        <label for="rate-4-{{producto.Id}}" class="fa-solid fa-star" (click)="calificar(producto, 4)"></label>
        <input type="radio" name="rate" id="rate-3-{{producto.Id}}">
        <label for="rate-3-{{producto.Id}}" class="fa-solid fa-star" (click)="calificar(producto, 3)"></label>
        <input type="radio" name="rate" id="rate-2-{{producto.Id}}">
        <label for="rate-2-{{producto.Id}}" class="fa-solid fa-star" (click)="calificar(producto, 2)"></label>
        <input type="radio" name="rate" id="rate-1-{{producto.Id}}">
        <label for="rate-1-{{producto.Id}}" class="fa-solid fa-star" (click)="calificar(producto, 1)"></label>
      </div>
      <a (click)="carrito(producto.Id)" class="btn-agregar">Agregar al carrito <i class="fa-solid fa-cart-shopping"></i></a><br>
      <a [routerLink]="['/detalleProducto']" class="ver-detalle" (click)="sumarVistas(producto)">Ver mas</a>
    </div>
  </div>
  <pagination-controls  class="my-pagination" (pageChange)="page = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>