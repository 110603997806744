<header *ngIf="IdUsuario ==0">
    <nav>
        <!--<section class="contenedor nav">-->
            <!--<div class="logo">
                <img src="assets/img/BuscOmida1.png" alt="">
            </div>-->
            <!--<div class="enlaces-header">
                <a routerLink="/"><i class="fa-solid fa-house"></i>&nbsp;nicio</a>
                <a routerLink="/carrito"><i class="fa-solid fa-cart-shopping"></i>&nbsp;Carrito</a>
                <a href="https://socio.buscomida.mx/inicio"><i class="fa-solid fa-user-tie"></i>&nbsp;¿Quieres ser socio?</a>
                <a routerLink="/registro">Registro</a>
                <a routerLink="/login">Iniciar sesión</a>
            </div>-->
            <!--<div class="hamburguer">
                <i class="fa-solid fa-bars"></i>-->
            <!--</div>-->

            <div class="topnav" id="myTopnav"  [ngClass]="status ? 'topnav' : 'topnav responsive'">
                <!--<a href="#home" class="active">Home</a>-->
                <a routerLink="/" class="active"><i class="fa-solid fa-house"></i>&nbsp;Inicio</a>
                <a routerLink="/carrito"><i class="fa-solid fa-cart-shopping"></i>&nbsp;Carrito</a>
                <a href="https://socio.buscomida.mx/inicio"><i class="fa-solid fa-user-tie"></i>&nbsp;¿Quieres ser socio?</a>
                <a routerLink="/registro">Registro</a>
                <a routerLink="/login">Iniciar sesión</a>
                <a href="javascript:void(0);" class="icon" (click)="clickEvent()">
                <i class="fa fa-bars"></i>
                </a>
            </div>
        <!--</section>-->
    </nav>
</header>
<header *ngIf="IdUsuario >0">
    <nav>
        <!--<section class="contenedor nav">
            <div class="logo">
                <img src="assets/img/BuscOmida1.png" alt="">
            </div>-->
            <!--<div class="enlaces-header">
                <a routerLink="/"><i class="fa-solid fa-house"></i>&nbsp;nicio</a>
                <a routerLink="/carrito"><i class="fa-solid fa-cart-shopping"></i> Carrito</a>
                <a href=""><i class="fa-solid fa-circle-user"></i>&nbsp;Perfil</a>
                <a href="https://socio.buscomida.mx/inicio"><i class="fa-solid fa-user-tie"></i>&nbsp;¿Quieres ser socio?</a>
                <a href="" (click)="cerrar()">Cerrar sesion</a>
               
            </div>-->
            <!--<div class="hamburguer">
                <i class="fa-solid fa-bars"></i>-->
            <!--</div>-->

            <div class="topnav" id="myTopnav"  [ngClass]="status ? 'topnav' : 'topnav responsive'">
                <!--<a href="#home" class="active">Home</a>-->
                <a routerLink="/" class="active"><i class="fa-solid fa-house"></i>&nbsp;Inicio</a>
                <a routerLink="/carrito"><i class="fa-solid fa-cart-shopping"></i>&nbsp;Carrito</a>
                <a href=""><i class="fa-solid fa-circle-user"></i>&nbsp;Perfil</a>
                <a href="https://socio.buscomida.mx/inicio"><i class="fa-solid fa-user-tie"></i>&nbsp;¿Quieres ser socio?</a>
                <a href="javascript:void(0);" class="icon" (click)="clickEvent()">
                <i class="fa fa-bars"></i>
                </a>
            </div>
        <!--</section>-->
    </nav>
</header>